import BookingForm from "@/components/BookingForm";
import CourseBadges from "@/components/CourseBadges";
import CoursePage from "@/components/CoursePage";
import Heading from "@/components/Heading";
import LinkButton from "@/components/LinkButton";
import Para from "@/components/Para";
import SpacePic from "@/components/SpacePic";
import { Box, Image, ListItem, UnorderedList } from "@chakra-ui/react";
import { PageProps } from "gatsby";
import React, { ReactElement } from "react";

export function EssentialScalaAbstract(): ReactElement {
  return (
    <Box>
      <CourseBadges days={3} />
      <Para>
        A conversion course to Scala for developers from other languages.
        We&apos;ll get you coding the right way as quickly as possible with
        equal doses of FP theory and hands-on labs. If you are an experienced
        developer taking your first steps in Scala, this is the course for you.
      </Para>
    </Box>
  );
}

function EssentialScalaOutline(): ReactElement {
  return (
    <Box>
      <Heading level="subsection">The Mechanics of Scala</Heading>
      {/* <Para>
        We start by building a mental model of the language until we are able to
        read, write, and reason about Scala code. We also get some practice
        writing code using three important tools: strings, functions, and lists.
      </Para> */}
      <UnorderedList mb="8">
        <ListItem>Expressions, types, and values</ListItem>
        <ListItem>Reasoning using substitution</ListItem>
        <ListItem>Method and value definitions</ListItem>
        <ListItem>Scala&apos;s type hierarchy</ListItem>
        <ListItem>Objects and classes</ListItem>
        <ListItem>Structure of a Scala application</ListItem>
      </UnorderedList>
      <Heading level="subsection">Modelling Data</Heading>
      {/* <Para>
        <i>Algebraic Data Types</i> are a powerful pattern for defining type
        safe data structures. They are complemented by
        <i>pattern matching</i>, a concise and robust way of defining operations
        on families of types.
      </Para> */}
      <UnorderedList mb="8">
        <ListItem>Algebraic Data Types</ListItem>
        <ListItem>Case classes and sealed traits</ListItem>
        <ListItem>Pattern matching</ListItem>
        <ListItem>Common examples of ADTs</ListItem>
      </UnorderedList>
      <Heading level="subsection">Collections and Computations</Heading>
      {/* <Para>
        Many of Scala&apos;s built-in collection types are ADTs, including
        Lists, Options, Eithers, and Tuples. We spend some time working with
        these types, building up a vocabulary of common operations like{" "}
        <i>map</i> and <i>flatMap</i>:
      </Para> */}
      <UnorderedList mb="8">
        <ListItem>Traversing Lists and Options</ListItem>
        <ListItem>Error handling with Option and Either</ListItem>
        <ListItem>The map and flatMap methods</ListItem>
        <ListItem>For comprehensions</ListItem>
        <ListItem>Monads!</ListItem>
      </UnorderedList>
      <Heading level="subsection">Generic Types</Heading>
      {/* <Para>
        We deepen our understanding of Scala mechanics by looking at{" "}
        <i>generic types</i> and type parameters, enabling us to write code that
        works with a range of other types:
      </Para> */}
      <UnorderedList mb="8">
        <ListItem>Type parameters on classes and methods</ListItem>
        <ListItem>Generalised Algebraic Data Types</ListItem>
        <ListItem>Variance and type bounds</ListItem>
        <ListItem>Functors!</ListItem>
      </UnorderedList>
      <Heading level="subsection">Type Classes</Heading>
      {/* <Para>
        We introduce <i>type classes</i>, an enormously powerful programming
        pattern for briding gaps between library and application code. We
        examine some examples from the Scala standard library, look at using
        type classes in application code, and look at defining new type classes
        from scratch:
      </Para> */}
      <UnorderedList mb="8">
        <ListItem>The type class pattern</ListItem>
        <ListItem>Implicit parameters and values</ListItem>
        <ListItem>Rules of implicit resolution</ListItem>
        <ListItem>Recursive implicit resolution</ListItem>
        <ListItem>Packaging implicits</ListItem>
      </UnorderedList>
      <Heading level="subsection">
        Asynchronous and Concurrent Programming in Scala
      </Heading>
      {/* <Para>
        In order to develop web services and applications in Scala, we need to
        know how to handle concurrency. We finish by examining how the
        sequencing patterns from earlier in the course can be used to simplify
        this complex domain:
      </Para> */}
      <UnorderedList mb="8">
        <ListItem>Parallelism versus concurrency</ListItem>
        <ListItem>Models of concurrency</ListItem>
        <ListItem>
          Practical exercises with your choice of:
          <UnorderedList>
            <ListItem>scala.concurrent, or</ListItem>
            <ListItem>Cats Effect</ListItem>
          </UnorderedList>
        </ListItem>
      </UnorderedList>
      {/* <Para>
        The final topic involves looking in detail at one of the handful of
        popular concurrency libraries available for Scala. We choose <i>one</i>{" "}
        of the following:
      </Para>
      <UnorderedList mb="8">
        <ListItem>scala.concurrent</ListItem>
        <ListItem>Cats Effect</ListItem>
        <ListItem>Monix</ListItem>
        <ListItem>ZIO</ListItem>
      </UnorderedList> */}
    </Box>
  );
}

function EssentialScalaBooks() {
  return (
    <Box flexGrow={0} flexShrink={0} width="200px" ml={["0", "8"]}>
      <Heading level="subsection">Course Books</Heading>
      <Para fontSize="xs">The content for this course is open source:</Para>
      <Image my="8" src="/images/books/essential-scala.jpg" />
      <Para textAlign="center">
        <LinkButton
          to="https://underscore.io/books/essential-scala/"
          size="sm"
          variant="outline"
        >
          Read Online
        </LinkButton>
      </Para>
    </Box>
  );
}

export default function EssentialScalaPage(_props: PageProps): ReactElement {
  return (
    <CoursePage
      title="Essential Scala (in Space)"
      category="scala"
      accent="red"
      abstract={<EssentialScalaAbstract />}
      bookingForm={<BookingForm defaultCourse="essential-scala" />}
      picture={<SpacePic name="rocket" maxW="96" mx="auto" />}
      outline={<EssentialScalaOutline />}
      sidebar={<EssentialScalaBooks />}
    />
  );
}
