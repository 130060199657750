import Heading from "@/components/Heading";
import { checkExhausted } from "@/util";
import { Box, BoxProps, Flex, Grid, ResponsiveValue } from "@chakra-ui/react";
import React, { ReactElement, ReactNode } from "react";

export interface PromoGridProps extends BoxProps {
  blocks: 1 | 2 | 3 | 4;
  children: ReactNode;
}

export interface PromoBlockProps {
  title: string;
  subtitle?: string;
  image?: ReactNode;
  button?: ReactNode;
  children: ReactNode;
}

function PromoGrid(props: PromoGridProps): ReactElement {
  const { children, blocks, ...rest } = props;

  let justifyContent: string;
  let autoColumns: ResponsiveValue<string>;
  let templateRows: ResponsiveValue<string>;

  switch (blocks) {
    case 1:
      justifyContent = "center";
      autoColumns = ["1fr", ".5fr"];
      templateRows = ["repeat(8, auto)"];
      break;

    case 2:
    case 3:
    case 4:
      justifyContent = "center";
      autoColumns = ["1fr", `repeat(${blocks}, .5fr)`];
      templateRows = ["repeat(8, auto)", "repeat(4, auto)"];
      break;

    default:
      checkExhausted(blocks);
  }

  return (
    <Grid
      autoFlow="column"
      justifyContent={justifyContent}
      autoColumns={autoColumns}
      templateRows={templateRows}
      columnGap="8"
      {...rest}
    >
      {children}
    </Grid>
  );
}

function PromoBlock(props: PromoBlockProps): ReactElement {
  const { title, subtitle, image, button, children } = props;

  return (
    <>
      <Flex mt="12" mb="6" justifyContent={["left", "center"]}>
        {image}
      </Flex>
      <Box mt="6" mb="0">
        <Heading
          level="section"
          size="lg"
          textAlign={["left", "center"]}
          mt="0"
          mb="0"
        >
          {title}
        </Heading>
        {subtitle && <Heading level="subsubsection">{subtitle}</Heading>}
      </Box>
      <Box mt="6">{children}</Box>
      <Box my="6" textAlign={["left", "center"]}>
        {button}
      </Box>
    </>
  );
}

export default { Grid: PromoGrid, Block: PromoBlock };
