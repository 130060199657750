import { CustomCoursePromos } from "@/components/CustomCoursePromos";
import Heading from "@/components/Heading";
import SpacePage from "@/components/SpacePage";
import SpaceSection from "@/components/SpaceSection";
import { PageProps } from "gatsby";
import React, { ReactElement } from "react";
import { ScalaCoursePromos } from "./scala";

export default function TrainingPage(_props: PageProps): ReactElement {
  return (
    <SpacePage title="Training" accent="purple" activeMenuItem="training">
      <SpaceSection bg="beige" pt="16" pb="16">
        <ScalaCoursePromos />
        {/* <TypeScriptCoursePromos /> */}
      </SpaceSection>
      <SpaceSection pt="24" pb="32" ct="lg">
        <CustomCoursePromos />
      </SpaceSection>
    </SpacePage>
  );
}
