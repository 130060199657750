import BookingForm from "@/components/BookingForm";
import CourseBadges from "@/components/CourseBadges";
import CoursePage from "@/components/CoursePage";
import Heading from "@/components/Heading";
import LinkButton from "@/components/LinkButton";
import Para from "@/components/Para";
import SpacePic from "@/components/SpacePic";
import { spacecatTrainingEnquiriesLink } from "@/reform";
import { Box, Image, ListItem, UnorderedList } from "@chakra-ui/react";
import { PageProps } from "gatsby";
import React, { ReactElement } from "react";

export function ScalaWithCatsAbstract(): ReactElement {
  return (
    <Box>
      <CourseBadges days={3} />
      <Para>
        An example-driven introduction to Typelevel&apos;s Cats library, aimed
        at intermediate-level Scala developers. We show you how to solve common
        programming problems with Cats, and look in depth at the theoretical
        building blocks that underpin these solutions.
      </Para>
    </Box>
  );
}

function ScalaWithCatsOutline(): ReactElement {
  return (
    <Box flexGrow={1} flexShrink={1}>
      <Heading level="subsection">Cats and Type Classes</Heading>
      <UnorderedList>
        <ListItem>The type class pattern</ListItem>
        <ListItem>Implicit parameters and values</ListItem>
        <ListItem>Rules of implicit resolution</ListItem>
        <ListItem>Recursive implicit resolution</ListItem>
        <ListItem>Type classes in Cats</ListItem>
      </UnorderedList>
      <Heading level="subsection">Counting with Cats</Heading>
      <UnorderedList>
        <ListItem>The Monoid and Semigroup type classes</ListItem>
        <ListItem>Examples of monoids from Scala</ListItem>
        <ListItem>Applications of monoids in big data</ListItem>
      </UnorderedList>
      <Heading level="subsection">Error Handling with Cats</Heading>
      <UnorderedList>
        <ListItem>Error handling with Either</ListItem>
        <ListItem>mapN, sequence, and traverse</ListItem>
        <ListItem>Accumulating errors</ListItem>
        <ListItem>parMapN, parSequence, and parTraverse</ListItem>
      </UnorderedList>
      <Heading level="subsection">Concurrent Programming with Cats</Heading>
      <UnorderedList>
        <ListItem>Cats Effect&apos;s IO monad</ListItem>
        <ListItem>Doing things in sequence</ListItem>
        <ListItem>Doing things in parallel</ListItem>
        <ListItem>Traversing and sequencing</ListItem>
      </UnorderedList>
      <Heading level="subsection">Decoupling Modules with Cats</Heading>
      <UnorderedList>
        <ListItem>The Functor, Applicative, and Monad type classes</ListItem>
        <ListItem>Higher kinded types</ListItem>
        <ListItem>Simplifying test code by switching monads</ListItem>
        <ListItem>Finally tagless encoding</ListItem>
        <ListItem>Natural transformations</ListItem>
      </UnorderedList>
    </Box>
  );
}

function ScalaWithCatsBooks() {
  return (
    <Box flexGrow={0} flexShrink={0}>
      <Heading level="subsection">Course Text</Heading>
      <Para fontSize="xs">The content for this course is open source:</Para>
      <Image my="8" src="/images/books/scala-with-cats.jpg" />
      <Para textAlign="center">
        <LinkButton
          to="https://underscore.io/books/scala-with-cats/"
          size="sm"
          variant="outline"
        >
          Read Online
        </LinkButton>
      </Para>
    </Box>
  );
}

export default function ScalaWithCatsPage(_props: PageProps): ReactElement {
  return (
    <CoursePage
      title="Scala with Cats (in Space)"
      category="scala"
      accent="red"
      abstract={<ScalaWithCatsAbstract />}
      bookingForm={<BookingForm defaultCourse="scala-with-cats" />}
      picture={<SpacePic name="box" maxW="96" mx="auto" />}
      outline={<ScalaWithCatsOutline />}
      sidebar={<ScalaWithCatsBooks />}
    />
  );
}
