import { spacecatTrainingEnquiriesLink } from "@/reform";
import { Flex } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import LinkButton from "./LinkButton";
import Promo from "./Promo";
import SpacePic from "./SpacePic";

export function CustomCoursePromos(): ReactElement {
  return (
    <Promo.Grid blocks={1}>
      <Promo.Block
        title="Custom Courses and Workshops"
        image={<SpacePic name="scala-logos" w="96" alignSelf="center" />}
        button={
          <Flex justifyContent="center" flexWrap="wrap">
            <LinkButton to={spacecatTrainingEnquiriesLink("custom")}>
              Get in Touch
            </LinkButton>
          </Flex>
        }
      >
        Need something that&apos;s customised to your team? We deliver bespoke
        courses and workshops covering Scala and its ecosystem. Get in touch to
        see what we can do for you.
      </Promo.Block>
    </Promo.Grid>
  );
}
