import CourseBadges from "@/components/CourseBadges";
import { CustomCoursePromos } from "@/components/CustomCoursePromos";
import EnquiryForm from "@/components/EnquiryForm";
import Heading from "@/components/Heading";
import LinkButton from "@/components/LinkButton";
import Promo from "@/components/Promo";
import SpacePage from "@/components/SpacePage";
import SpacePic from "@/components/SpacePic";
import SpaceSection from "@/components/SpaceSection";
import { PageProps } from "gatsby";
import React, { ReactElement } from "react";
import { EssentialScalaAbstract } from "./essential-scala";
import { ScalaWithCatsAbstract } from "./scala-with-cats";

export function ScalaCoursePromos(): ReactElement {
  return (
    <Promo.Grid blocks={3}>
      <Promo.Block
        title="Essential Scala"
        image={<SpacePic name="rocket" />}
        button={
          <LinkButton variant="outline" to="/training/essential-scala">
            Course Details
          </LinkButton>
        }
      >
        <EssentialScalaAbstract />
      </Promo.Block>
      <Promo.Block
        title="Scala with Cats"
        image={<SpacePic name="box" />}
        button={
          <LinkButton variant="outline" to="/training/scala-with-cats">
            Course Details
          </LinkButton>
        }
      >
        <ScalaWithCatsAbstract />
      </Promo.Block>
    </Promo.Grid>
  );
}

export default function ScalaTrainingPage(_props: PageProps): ReactElement {
  return (
    <SpacePage title="Scala Training" accent="red" activeMenuItem="training">
      <SpaceSection bg="beige" pt="16" pb="16">
        <Heading level="page" mb="8">
          Scala Training
        </Heading>
        <ScalaCoursePromos />
      </SpaceSection>
      <SpaceSection pt="24" pb="32" ct="lg">
        <CustomCoursePromos />
      </SpaceSection>
    </SpacePage>
  );
}
