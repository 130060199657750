// TODO: Load from .envrc:
const GATSBY_REFORM_URL =
  "https://forms.reform.app/spacecatio/training-enquiries/c90b2k";
const GATSBY_REFORM_COURSE_FIELD_ID = "7d97fe57-8564-4f31-8cb1-cef5453b16a0";

const courseChoices = {
  "essential-scala": "Essential Scala",
  "scala-with-cats": "Scala with Cats",
  custom: "Custom (special requirements or unsure)",
};

export function spacecatTrainingEnquiriesLink(
  courseId?: keyof typeof courseChoices
): string {
  return courseId == null
    ? GATSBY_REFORM_URL
    : `${GATSBY_REFORM_URL}?${GATSBY_REFORM_COURSE_FIELD_ID}=${encodeURIComponent(
        courseChoices[courseId]
      )}`;
}
